#rap-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 2;
  cursor: pointer;
}

.rap-manager,
.rap-target-container {
  display: inline-block;
  position: relative;
}

.rap-left {
  left: -20px !important;
}
.rap-bottom {
  top: 15px !important;
}
.rap-top {
  top: -15px !important;
}
.rap-right {
  left: 20px !important;
}






.ar {
    transform: rotate(-180deg);
}
.at {
    transform: rotate(90deg);
}
.ab {
    transform: rotate(-90deg);
}